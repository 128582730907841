<template>
  <div>
    <slot name="header" />
    <div class="container mx-auto mt-8 pb-12">
      <div
        v-if="Array.isArray(data.model) && data.options.length > 0"
        class="max-w-lg mx-auto">
        <checkbox-list
          :options="data.options"
          option-value="title"
          option-key="title"
          v-model="data.model"
          class="pt-8 sm:grid gap-x-4">
          <template v-slot="{ item: { title } }">
            <span class="font-bold">{{ title }}</span>
          </template>
          <template v-slot:footer="{ item: { description } }">
            <div class="ml-10 pl-1">
              <editor-content v-html="markdown(description)" />
            </div>
          </template>
        </checkbox-list>
      </div>
      <div
        v-else
        class="max-w-2xl mx-auto">
        <div class="flex justify-center">
          <input-checkbox
            v-model="accepted"
            :label="'Jeg godtar ' + $app.name + ' sin personværnerklæring'"
            class="mb-4"
            label-class="font-bold accept-terms" />
        </div>
        <p class="text-sm text-muted">
          Dine personopplysninger brukes til å øke brukervennligheten på denne nettsiden og andre formål som beskrevet i våre <a
            :href="privacyPolicyUrl"
            target="_blank"
            class="underline">personvernregler</a>.
        </p>
      </div>
    </div>
    <slot :is-valid="isValid" />
  </div>
</template>

<script>
import marked from "marked"

export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },

    formData: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      accepted: false
    }
  },

  methods: {
    handleContinue () {
      if (this.isValid) {
        this.$emit("continue")
      }
    },

    markdown (value) {
      return marked.parseInline(value)
    }
  },

  computed: {
    isValid () {
      if (!Array.isArray(this.data.model)) {
        return this.accepted === true
      } else {
        return this.data.model.length === this.data.options.length
      }
    },

    privacyPolicyUrl () {
      return this.$app.privacy_policy_url || "#"
    }
  }
}
</script>

<style lang="scss">
  /* purgecss start ignore */
  .accept-terms {
    color: var(--accept-terms-color);
    a {
      text-decoration: underline;
    }
  }
  /* purgecss end ignore */
</style>
